export const styles = {
  section: (isAuthor?: boolean) => ({
    variant: 'grids.CompactSectionContentPadding',
    minHeight: isAuthor ? '60vh !important' : '40vh !important',
    mt: 'headerHeight',
    bg: 'primary',
    '> div': {
      minHeight: 'max-content !important',
      alignItems: 'flex-start',
    },
  }),
  pageWrapper: {
    maxWidth: ['maxBlogContentWidthMobile', 'maxBlogContentWidthMobile', 'maxBlogContentWidthDesktop'],
    display: 'flex',
  },
  list: {
    pb: 8,
  },
};
