export const styles = {
  wrapper: {
    maxWidth: '619px',
  },
  tagName: {
    color: 'background',
    pt: 3,
  },
  tagDescription: {
    color: 'text',
    pt: 2,
  },
  moreText: {
    color: 'background',
    pb: 6,
  },
};
