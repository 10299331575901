import React from 'react';
import { Box, SubTitle, Paragraph } from '@themed';
import { styles } from './TagHeader.styles';

interface TagHeaderProps {
  title: string;
  description: string;
}

export const TagHeader = ({ title, description }: TagHeaderProps) => (
  <Box sx={styles.wrapper}>
    <SubTitle sx={styles.moreText}>
      {title}
    </SubTitle>
    <Paragraph sx={styles.tagDescription}>
      {description}
    </Paragraph>
  </Box>
);
